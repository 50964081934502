import { createSelector } from "reselect";
import { get } from "lodash";

import { IAppState } from "../../types";
import { mapDropdownOptions } from "../../services/helpers.service";
import transferService from "../../services/transfer.service";
import {
  transferDataByCountrySelector,
  organizationSettingsSelector,
  payoutTypesComponentsConfigSelector,
  organizationIdSelector,
  sourceCountrySelector,
} from "../organization/organization.selectors";
import { BankAccount } from "../recipient/recipient.types";
import organizationsConfig from "../../../../../src/organizations.conf.json";

import {
  TransferDetailsConstants,
  TransactionStatuses,
  PayoutTypesCategories,
} from "./transfer.constants";
import { IRecipientValidationData } from "./transfer.types";

const DELIVERY_METHODS_ORDER: { [propName: string]: number } = {
  CASH: 1,
  MOBILE_TOPUP: 2,
  BANK_ACCOUNT: 3,
  AIR_TIME: 4,
};

const {
  SOURCE_COUNTRY,
  DESTINATION_COUNTRY,
  PAYOUT_TYPE,
  SOURCE_CURRENCY,
  DESTINATION_CURRENCY,
  SOURCE_AMOUNT,
  DESTINATION_AMOUNT,
  TRANSFER_PRICE,
  TRANSFER_PRICE_ERROR,
  PURPOSE_OF_TRANSFER,
  SOURCE_OF_FUNDS,
  CITIES_BY_COUNTRY,
  BANKS_BY_COUNTRY,
  MOBILE_OPERATORS_BY_COUNTRY,
  MOBILE_OPERATOR_BY_PHONE_NUMBER,
  CELLPHONE_PRODUCTS,
  BANK_BRANCHES,
  TRANSFER_RECIPIENT,
  PICKUP_LOCATIONS,
  FUNDING_SOURCE,
  BANK_ACCOUNT_TRANSFER_INFO,
  TRANSACTION_NEW_RESPONSE,
  CREDIT_CARD_DETAILS,
  SECURE_3D_INFO,
  TRANSACTION_DATA_BY_QUERY_CODE,
  CASH_CODE,
  ACTIVE_COMPLIANCE_RULE,
  PROMO_CODE,
  REVERSE_CALCULATION,
  OPERATOR_ID,
  PROCESSOR_BANKS,
  BIC,
  QUERY_BY_CODE_PAYLOAD,
  RECEIPT_DATA,
  AIR_TIME_PRODUCT,
  AIR_TIME_PHONE,
  IFSC_BANK_DETAILS,
  IS_SPLIT_TRANSACTION,
  COMPLIANCE_CHECK,
  RECIPIENT_VALIDATION,
  FEE_VISIBILITY,
  OTP_ERROR_TEXT,
  OTP_RESPONSE_FLAG,
  OTP_RESPONSE_TEXT,
  OTP_IS_VERIFICATION_REQUIRED,
  OTP_CODE,
} = TransferDetailsConstants;

export const recipientValidationDataSelector = ({
  transfer,
}: IAppState): IRecipientValidationData | null =>
  get(transfer, RECIPIENT_VALIDATION);

export const transferDetailsSelector = ({ transfer }: IAppState) => transfer;

export const splitTransactionSelector = ({ transfer }: IAppState) =>
  get(transfer, IS_SPLIT_TRANSACTION, false);

export const destinationCountrySelector = ({ transfer }: IAppState) =>
  get(transfer, DESTINATION_COUNTRY, "");

export const transferOriginCountrySelector = ({ transfer }: IAppState) =>
  get(transfer, SOURCE_COUNTRY, "");

export const receiptDataSelector = ({ transfer }: IAppState) =>
  get(transfer, RECEIPT_DATA);

export const payoutTypeSelector = ({ transfer }: IAppState): string =>
  get(transfer, PAYOUT_TYPE, "");

export const sourceCurrencySelector = ({ transfer }: IAppState) =>
  get(transfer, SOURCE_CURRENCY, "");

export const destinationCurrencySelector = ({ transfer }: IAppState) =>
  get(transfer, DESTINATION_CURRENCY, "");

export const sourceAmountSelector = ({ transfer }: IAppState) =>
  get(transfer, SOURCE_AMOUNT);

export const destinationAmountSelector = ({ transfer }: IAppState) =>
  get(transfer, DESTINATION_AMOUNT);

export const priceResponseSelector = ({ transfer }: IAppState) =>
  get(transfer, TRANSFER_PRICE);

export const priceResponseErrorMessageSelector = ({ transfer }: IAppState) =>
  get(transfer, [TRANSFER_PRICE_ERROR, "message"]);

export const purposeOfTransferSelector = ({ transfer }: IAppState) =>
  get(transfer, PURPOSE_OF_TRANSFER);

export const sourceOfFundsSelector = ({ transfer }: IAppState) =>
  get(transfer, SOURCE_OF_FUNDS);

export const banksSelector = ({ transfer }: IAppState) =>
  get(transfer, BANKS_BY_COUNTRY, []);

export const mobileOperatorsSelector = ({ transfer }: IAppState) =>
  get(transfer, MOBILE_OPERATORS_BY_COUNTRY, []);

export const mobileOperatorSelector = ({ transfer }: IAppState) =>
  get(transfer, MOBILE_OPERATOR_BY_PHONE_NUMBER);

export const cellPhoneProductsSelector = ({ transfer }: IAppState) => ({
  operatorName: get(transfer, [CELLPHONE_PRODUCTS, "operatorName"]),
  productsOptions: get(transfer, [CELLPHONE_PRODUCTS, "products"], []).map(
    (item: { receivedAmount: number; receivedCurrency: string }) => ({
      label: `${item.receivedAmount} ${item.receivedCurrency}`,
      value: item,
    }),
  ),
});

export const airTimeProductSelector = ({ transfer }: IAppState) =>
  get(transfer, AIR_TIME_PRODUCT);

export const banksBranchesSelector = ({ transfer }: IAppState) =>
  get(transfer, BANK_BRANCHES, []);

export const pickupLocationsSelector = ({ transfer }: IAppState) =>
  get(transfer, PICKUP_LOCATIONS, []);

export const transferRecipientSelector = ({ transfer }: IAppState) =>
  get(transfer, TRANSFER_RECIPIENT);

export const bankAccountDetailsSelector = ({ transfer }: IAppState) =>
  get(transfer, BANK_ACCOUNT_TRANSFER_INFO, {});

export const fundingSourceSelector = ({ transfer }: IAppState) =>
  get(transfer, FUNDING_SOURCE);

export const airTimePhoneSelector = ({ transfer }: IAppState) =>
  get(transfer, AIR_TIME_PHONE);

export const complianceRulesSelector = ({ transfer }: IAppState) =>
  get(transfer, ACTIVE_COMPLIANCE_RULE);

export const complianceCheckSelector = ({ transfer }: IAppState) =>
  get(transfer, COMPLIANCE_CHECK);

export const complianceDocsSelector = createSelector(
  complianceRulesSelector,
  (complianceRule: any) => get(complianceRule, "requiredDocuments"),
);

export const promocodeSelector = ({ transfer }: IAppState) =>
  get(transfer, PROMO_CODE);

export const reverseCalcSelector = ({ transfer }: IAppState) =>
  get(transfer, REVERSE_CALCULATION, false);

export const feeVisibility = ({ transfer }: IAppState) =>
  get(transfer, FEE_VISIBILITY, true);

export const banksOptionsSelector = createSelector(
  banksSelector,
  banks =>
    banks.map(
      ({
        bankName,
        bankCode,
        externalCode,
      }: {
        bankName: string;
        bankCode: string;
        externalCode: string;
      }) => ({
        label: bankName,
        value: bankCode,
        externalCode,
      }),
    ),
);

export const citiesSelector = ({ transfer }: IAppState) =>
  get(transfer, CITIES_BY_COUNTRY, []);

export const citiesOptionsSelector = createSelector(
  citiesSelector,
  cities =>
    cities.map(({ code, name }: { name: string; code: string }) => ({
      label: name,
      value: code,
    })),
);

export const mobileOperatorsOptionsSelector = createSelector(
  mobileOperatorsSelector,
  operators =>
    operators.map(
      ({
        mobileOperatorName,
        mobileOperatorCode,
        externalCode,
      }: {
        mobileOperatorName: string;
        mobileOperatorCode: string;
        externalCode: string;
      }) => ({
        label: mobileOperatorName,
        value: mobileOperatorCode,
        externalCode,
      }),
    ),
);

export const mobileOperatorRecipientOptionsSelector = createSelector(
  mobileOperatorSelector,
  operator => operator,
);

export const pickupLocationsOptionsSelector = createSelector(
  pickupLocationsSelector,
  locations =>
    locations.map(
      ({
        locationName,
        locationCode,
        externalCode,
        bankName,
      }: {
        locationName: string;
        locationCode: string;
        externalCode: string;
        bankName: string;
      }) => ({
        label: locationName + " (Provided by " + bankName + ")",
        value: locationName,
        externalCode,
        locationCode,
      }),
    ),
);

export const bankBranchesOptionsSelector = createSelector(
  banksBranchesSelector,
  banks =>
    banks.map(
      ({
        branchName,
        branchCode,
        externalCode,
      }: {
        branchName: string;
        branchCode: string;
        externalCode: string;
      }) => ({
        label: branchName || "Any Bank Branch",
        value: branchName || "Any Bank Branch",
        externalCode,
        branchCode,
      }),
    ),
);

export const payoutTypesOptionsSelector = createSelector(
  sourceCountrySelector,
  destinationCountrySelector,
  transferDataByCountrySelector,
  organizationIdSelector,
  (sourceCountry, selectedDestCountry, countriesConfig, orgId) => {
    const data = Object.keys(get(countriesConfig, selectedDestCountry, {}));
    const options = mapDropdownOptions(
      // sourceCountry === "NL" ? data.filter(type => type !== "AIR_TIME") : data, // TODO switch Airtime
      data,
      transferService.getPayoutTypeName,
    );

    if (organizationsConfig.unitylink.id === orgId) {
      return options
        .map(item => ({
          ...item,
          order: DELIVERY_METHODS_ORDER[item.value] || 100,
        }))
        .sort((i1, i2) => i1.order - i2.order);
    }

    return options;
  },
);

export const payoutTypeCategorySelector = createSelector(
  transferDataByCountrySelector,
  destinationCountrySelector,
  payoutTypeSelector,
  (countriesConfig, destCountry, payoutType) =>
    get(countriesConfig, [destCountry, payoutType, "category"]),
);

export const transactionNewResponseSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, TRANSACTION_NEW_RESPONSE),
);

export const creditCardSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, CREDIT_CARD_DETAILS),
);

export const secure3dInfoSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, SECURE_3D_INFO),
);

export const activeComplianceRuleSelector = createSelector(
  complianceRulesSelector,
  rules => get(rules, "activeRule"),
);
export const transactionByQueryCodeSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, TRANSACTION_DATA_BY_QUERY_CODE),
);

export const selectedRecipientSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, "recipient"),
);

export const ifscBankSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, IFSC_BANK_DETAILS),
);

export const cashCodeSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, CASH_CODE),
);

export const otpErrorTextSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, OTP_ERROR_TEXT),
);

export const otpResponseTextSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, OTP_RESPONSE_TEXT),
);

export const otpResponseFlagSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, OTP_RESPONSE_FLAG),
);

export const operatorIdSelector = createSelector(
  transferDataByCountrySelector,
  destinationCountrySelector,
  payoutTypeSelector,
  (transferDataByCountry, destCountry, payoutType) =>
    get(transferDataByCountry, [destCountry, payoutType, OPERATOR_ID]),
);

export const payoutTypeFormNameSelector = createSelector(
  payoutTypesComponentsConfigSelector,
  operatorIdSelector,
  payoutTypeSelector,
  destinationCountrySelector,
  (payoutTypesComponentsConfig, operatorId, payoutType, destCountry) => {
    const formName =
      get(payoutTypesComponentsConfig, [operatorId, payoutType, destCountry]) ||
      get(payoutTypesComponentsConfig, [operatorId, payoutType, "DEFAULT"]);

    return formName || "";
  },
);

export const lawLinksUrlSelector = createSelector(
  organizationSettingsSelector,
  orgSettings => ({
    privacyPolicyUrl: get(orgSettings, "privacyPolicyUrl"),
    termsAndConditionsUrl: get(orgSettings, "termsAndConditionsUrl"),
  }),
);

export const privacyUrlSelector = createSelector(
  organizationSettingsSelector,
  orgSettings => get(orgSettings, "privacyPolicyUrl"),
);

export const termsAndConditionsUrlSelector = createSelector(
  organizationSettingsSelector,
  orgSettings => get(orgSettings, "termsAndConditionsUrl"),
);

const processorBanksSelector = ({ transfer }: IAppState) =>
  get(transfer, PROCESSOR_BANKS) || [];

export const bicSelector = ({ transfer }: IAppState) => get(transfer, BIC);
export const queryByCodePayloadSelector = ({ transfer }: IAppState) =>
  get(transfer, QUERY_BY_CODE_PAYLOAD);

export const processorBanksOptionsSelector = createSelector(
  processorBanksSelector,
  banks =>
    banks.map((item: { bankCode: string; bankName: string }) => ({
      label: item.bankName,
      value: item.bankCode,
    })),
);

export const lastTransactionCodeSelector = createSelector(
  transactionByQueryCodeSelector,
  data => {
    const reservationCode = get(data, "transactionDetails.reservationCode");
    const depositCode = get(data, "transactionDetails.depositCode");
    const transactionStatus = get(data, "transactionDetails.transactionStatus");

    const transactionCode =
      transactionStatus !== TransactionStatuses.PRE_TXN && reservationCode
        ? reservationCode
        : depositCode;

    return transactionCode;
  },
);

export const recipientBankAccountSelector = createSelector(
  payoutTypeSelector,
  transferRecipientSelector,
  (payoutType, recipientData) => {
    const bankAccount =
      (get(recipientData, "bankAccounts") || []).find(
        (account: BankAccount) => account.payoutType === payoutType,
      ) || new BankAccount(payoutType);

    return bankAccount;
  },
);

export const otpIsVerificationRequiredSelector = createSelector(
  transferDetailsSelector,
  transferDetails => get(transferDetails, OTP_IS_VERIFICATION_REQUIRED),
);

export const isRecipientValidationRequiredSelector = createSelector(
  transferDetailsSelector,
  transferDetails => {
    const validCountries = ["GH"];
    const recipientCountry = get(transferDetails, "recipient.country");

    /**
     * Due to validator changed
     */
    return (
      transferDetails.payoutType === PayoutTypesCategories.BANK_ACCOUNT ||
      transferDetails.payoutType === PayoutTypesCategories.MOBILE_TOPUP
    );

    return (
      validCountries.indexOf(recipientCountry) !== -1 &&
      (transferDetails.payoutType === PayoutTypesCategories.BANK_ACCOUNT ||
        transferDetails.payoutType === PayoutTypesCategories.MOBILE_TOPUP ||
        transferDetails.payoutType === PayoutTypesCategories.MOBILE_TOP_UP)
    );
  },
);

export const isRecipientCanBeValidatedOnStartSelector = createSelector(
  transferDetailsSelector,
  transferDetails => {
    return (
      transferDetails.payoutType === PayoutTypesCategories.MOBILE_TOPUP ||
      transferDetails.payoutType === PayoutTypesCategories.MOBILE_TOP_UP
    );
  },
);

export const selectOtpCode = ({ transfer }: IAppState) =>
  get(transfer, OTP_CODE, undefined);
